// import Vue from 'vue'
import dayjs from 'dayjs'

// 加载中文语言包
import 'dayjs/locale/zh-cn'

import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// 配置使用处理相对时间的插件
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)

// 配置使用中文语言包
dayjs.locale('zh-cn')

// console.log(dayjs().format('DD/MM/YYYY'))

// 时区
const tz = dayjs.tz.guess()
// console.log(dayjs.utc('2019-03-06T09:11:55Z').format())
// console.log(dayjs.utc('2019.03.06 09:11:55').format())
// console.log(dayjs.utc('2019.03.06 09:11:55') - dayjs.utc())
// console.log(dayjs());
// '2000-01-01T06:01:02Z'
// console.log(dayjs.utc().utcOffset(1, true).format() );
// dayjs().utcOffset(8)
// var a = dayjs()
// a.format() //2019-03-06T08:00:00+08:00
// a.utc().format() // 2019-03-06T00:00:00Z
// console.log(a.format(), a.utc().format() );

export const dayjsFormat = (date) => {
    const cur = date || dayjs()
    const day = dayjs.tz(dayjs(cur), tz)
    return dayjs(day)
}

export const utcDateSubtract = (date) => {
    if (typeof date !== 'string') return date
    date = `${date.replace(/\./g,"-").replace(' ', 'T')}Z`
    console.log(date);
    return dayjs.utc(date) - dayjs.utc()
}

// 全局过滤器：处理相对时间
//  Vue.filter('relativeTime', value => {
//      return dayjs().to(dayjs(value))
// })

